var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":15,"column":31}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                            <div class=\"tippy-zulip-tooltip\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "last_seen", {"start":{"line":8,"column":83},"end":{"line":8,"column":92}} ), depth0))
    + "\">\n                                <span class=\""
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":9,"column":47},"end":{"line":9,"column":64}} ), depth0))
    + " user_circle user_profile_presence\" data-presence-indicator-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":9,"column":137},"end":{"line":9,"column":144}} ), depth0))
    + "\"></span>\n                            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            <div>\n                                <i class=\"fa fa-ban deactivated-user-icon tippy-zulip-tooltip\" data-tippy-content=\"Deactivated user\"></i>\n                            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                        <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"user-profile-manage-own-edit-button\" href=\"/#settings/profile\">\n                            <i class=\"zulip-icon zulip-icon-edit tippy-zulip-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":106},"end":{"line":26,"column":127}}}))
    + "\" aria-hidden=\"true\"></i>\n                        </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"user-profile-manage-others-edit-button\">\n                            <i class=\"zulip-icon zulip-icon-edit tippy-zulip-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Manage user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":106},"end":{"line":31,"column":126}}}))
    + "\" aria-hidden=\"true\"></i>\n                        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div id=\"email\" class=\"default-field\">\n                                        <div class=\"name\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":58},"end":{"line":46,"column":72}}}))
    + "</div>\n                                        <div class=\"value\">"
    + alias1(container.lambda(container.strict(depth0, "email", {"start":{"line":47,"column":61},"end":{"line":47,"column":66}} ), depth0))
    + "</div>\n                                    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_system_bot"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":57,"column":44},"end":{"line":63,"column":51}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"value\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"System bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":63},"end":{"line":58,"column":82}}}))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"value\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":63},"end":{"line":60,"column":75}}}))
    + "\n                                                <span class=\"lowercase\">("
    + alias1(container.lambda(container.strict(depth0, "user_type", {"start":{"line":61,"column":75},"end":{"line":61,"column":84}} ), depth0))
    + ")</span>\n                                            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                                            <div class=\"value\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_type", {"start":{"line":65,"column":65},"end":{"line":65,"column":74}} ), depth0))
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"default-field\">\n                                        <div class=\"name\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Local time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":58},"end":{"line":74,"column":77}}}))
    + "</div>\n                                        <div class=\"value\">"
    + alias1(container.lambda(container.strict(depth0, "user_time", {"start":{"line":75,"column":61},"end":{"line":75,"column":70}} ), depth0))
    + "</div>\n                                    </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " class=\"guest-avatar\" ";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"field-section\">\n                                        <div class=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":58},"end":{"line":90,"column":75}}}))
    + "</div>\n                                        <div class=\"bot_info_value\">"
    + alias2(container.lambda(container.strict(depth0, "bot_type", {"start":{"line":91,"column":70},"end":{"line":91,"column":78}} ), depth0))
    + "</div>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"bot_owner"),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":36},"end":{"line":100,"column":43}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"field-section bot_owner_user_field\" data-field-id=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"bot_owner"), "user_id", {"start":{"line":94,"column":101},"end":{"line":94,"column":118}} ), depth0))
    + "\">\n                                        <div class=\"name\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":58},"end":{"line":95,"column":72}}}))
    + "</div>\n                                        <div class=\"pill-container not-editable\">\n                                            <div class=\"input\" contenteditable=\"false\" style=\"display: none;\"></div>\n                                        </div>\n                                    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + ((stack1 = container.invokePartial(require("./user_custom_profile_fields.hbs"),depth0,{"name":"user_custom_profile_fields","hash":{"profile_fields":lookupProperty(depth0,"profile_data")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"header-section\">\n                                <h3 class=\"stream-tab-element-header\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Subscribe {full_name} to channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":70},"end":{"line":112,"column":111}}}))
    + "</h3>\n                            </div>\n                            "
    + ((stack1 = container.invokePartial(require("./user_profile_subscribe_widget.hbs"),depth0,{"name":"user_profile_subscribe_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"user-profile-modal\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":1,"column":64},"end":{"line":1,"column":71}} ), depth0))
    + "\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container new-style\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"dialog_title\">\n            <div class=\"modal__header\">\n                <h1 class=\"modal__title user-profile-name-heading\" id=\"name\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_bot"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":16,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"user-profile-name\">"
    + ((stack1 = container.invokePartial(require("./user_full_name.hbs"),depth0,{"name":"user_full_name","hash":{"name":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n                    <a class=\"copy-link-to-user-profile user-profile-manage-own-copy-link-button\" tabindex=\"0\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":21,"column":127},"end":{"line":21,"column":134}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-link tippy-zulip-tooltip \" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy link to profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":103},"end":{"line":22,"column":132}}}))
    + "\" aria-hidden=\"true\"></i>\n                    </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":28,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"can_manage_profile"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":33,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":57},"end":{"line":35,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </div>\n            <div id=\"tab-toggle\" class=\"center\"></div>\n            <main class=\"modal__body\" id=\"body\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n                <div class=\"tab-data\">\n                    <div class=\"tabcontent active\" id=\"profile-tab\">\n                        <div class=\"top\">\n                            <div class=\"col-wrap col-left\">\n                                <div id=\"default-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":49,"column":43}}})) != null ? stack1 : "")
    + "                                    <div id=\"user-id\" class=\"default-field\">\n                                        <div class=\"name\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":58},"end":{"line":51,"column":74}}}))
    + "</div>\n                                        <div class=\"value\">"
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":52,"column":61},"end":{"line":52,"column":68}} ), depth0))
    + "</div>\n                                    </div>\n                                    <div id=\"user-type\" class=\"default-field\">\n                                        <div class=\"name\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":58},"end":{"line":55,"column":71}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":56,"column":40},"end":{"line":66,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n                                    <div id=\"date-joined\" class=\"default-field\">\n                                        <div class=\"name\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Joined",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":58},"end":{"line":69,"column":73}}}))
    + "</div>\n                                        <div class=\"value\">"
    + alias3(alias2(alias1(depth0, "date_joined", {"start":{"line":70,"column":61},"end":{"line":70,"column":72}} ), depth0))
    + "</div>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"user_time"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":36},"end":{"line":77,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                            <div class=\"col-wrap col-right\">\n                                <div id=\"avatar\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":49},"end":{"line":81,"column":99}}})) != null ? stack1 : "")
    + "\n                                  style=\"background-image: url('"
    + alias3(alias2(alias1(depth0, "user_avatar", {"start":{"line":82,"column":66},"end":{"line":82,"column":77}} ), depth0))
    + "');\">\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"bottom\">\n                            <div id=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":88,"column":32},"end":{"line":103,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"tabcontent\" id=\"user-profile-streams-tab\">\n                        <div class=\"alert stream_list_info\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_user_subscribe_widget"),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":24},"end":{"line":115,"column":31}}})) != null ? stack1 : "")
    + "                        <div class=\"stream-list-top-section\">\n                            <div class=\"header-section\">\n                                <h3 class=\"stream-tab-element-header\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Subscribed channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":70},"end":{"line":118,"column":98}}}))
    + "</h3>\n                            </div>\n                            <input type=\"text\" class=\"stream-search modal_text_input\" placeholder=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Filter channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":99},"end":{"line":120,"column":123}}}))
    + "\" />\n                            <button type=\"button\" class=\"clear_search_button\" id=\"clear_stream_search\">\n                                <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                            </button>\n                        </div>\n                        <div class=\"subscription-stream-list empty-list\">\n                            <table class=\"user-stream-list\" data-empty=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"No channel subscriptions.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":72},"end":{"line":126,"column":105}}}))
    + "\" data-search-results-empty=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"No matching channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":134},"end":{"line":126,"column":163}}}))
    + "\"></table>\n                        </div>\n                    </div>\n\n                    <div class=\"tabcontent\" id=\"user-profile-groups-tab\">\n                        <div class=\"subscription-group-list empty-list\">\n                            <table class=\"user-group-list\" data-empty=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"No user group subscriptions.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":71},"end":{"line":132,"column":107}}}))
    + "\"></table>\n                        </div>\n                    </div>\n                    <div class=\"tabcontent\" id=\"manage-profile-tab\"></div>\n                </div>\n            </main>\n            <div class=\"manage-profile-tab-footer\">\n                <footer class=\"modal__footer\">\n                    <div class=\"save-success\"></div>\n                    <button type=\"button\" class=\"modal__btn dialog_exit_button\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":92},"end":{"line":141,"column":125}}}))
    + "\" data-micromodal-close>"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":149},"end":{"line":141,"column":164}}}))
    + "</button>\n                    <button type=\"button\" class=\"modal__btn dialog_submit_button\">\n                        <span>"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Save changes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":30},"end":{"line":143,"column":50}}}))
    + "</span>\n                        <div class=\"modal__spinner\"></div>\n                    </button>\n                </footer>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});